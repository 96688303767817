// React
import React from "react";

// Semantic UI
import { Message } from "semantic-ui-react";

// Constants
import { MESSAGE_TYPE, COMPONENTS } from "../../utils/Constants";

const getMessageFromApolloError = (apolloError) => {
  const { graphQLErrors, networkError } = apolloError;

  const message = {
    header: "Une erreur est survenue",
    location: COMPONENTS.COMMON.INDEX,
    type: MESSAGE_TYPE.NEGATIVE,
  };

  if ((!graphQLErrors || graphQLErrors.length === 0) && !networkError) {
    message.content = apolloError.toString();
  }

  if (graphQLErrors && graphQLErrors.length !== 0) {
    message.messageList = graphQLErrors.map(({ message }) => message);
  }

  if (networkError) {
    message.content = networkError.toString();
  }

  return message;
};

const ErrorMessage = ({ error, header = "Une erreur s'est produite" }) => {
  const { graphQLErrors, networkError } = error;

  if ((!graphQLErrors || graphQLErrors.length === 0) && !networkError) {
    return (
      <Message error>
        <Message.Header>Une erreur inconnue est survenue</Message.Header>
        <p>{error.toString()}</p>
      </Message>
    );
  }

  if (graphQLErrors && graphQLErrors.length !== 0) {
    return (
      <Message
        error
        header={header}
        list={graphQLErrors.map(({ message }) => message)}
      ></Message>
    );
  }

  if (networkError) {
    if (
      networkError.message ===
      "JSON.parse: unexpected character at line 1 column 1 of the JSON data"
    ) {
      return (
        <Message error>
          <Message.Header>Une erreur réseau est survenue</Message.Header>
          <p>{networkError.bodyText}</p>
        </Message>
      );
    }
    return (
      <Message error>
        <Message.Header>Une erreur réseau est survenue</Message.Header>
        <p>{networkError.toString()}</p>
      </Message>
    );
  }

  return <Message error>Une erreur inconnue s'est produite</Message>;
};

export { getMessageFromApolloError, ErrorMessage };
export default ErrorMessage;
