// React
import React from "react";

// Semantic ui
import { Message, Transition, List } from "semantic-ui-react";

// Constants
import { MESSAGE_TYPE } from "../../utils/Constants";

// GraphQL
import { useQuery } from "@apollo/client";
import { GET_ALL_MESSAGES } from "../../_GraphQL/queries";
import { messageSetVar } from "../../_GraphQL/message";

const MessageDisplayer = ({ message }) => {
  let positive = message.type === MESSAGE_TYPE.POSITIVE;
  let warning = message.type === MESSAGE_TYPE.WARNING;
  let negative = message.type === MESSAGE_TYPE.NEGATIVE;

  function removeMessage() {
    messageSetVar(messageSetVar().filter((m) => m.id !== message.id));
  }

  if (message.autoClose) {
    setTimeout(removeMessage, message.closeTime);
  }

  let body;

  if (message.rawHtml) {
    body = (
      <div
        style={{
          maxHeight: 800,
          overflow: "scroll",
        }}
      >
        <div
          className="reset-some-element"
          dangerouslySetInnerHTML={{ __html: message.content }}
        />
      </div>
    );
  } else {
    body = message.content ? (
      message.content
    ) : (
      <Message.List items={message.messageList} />
    );
  }

  return (
    <Message
      positive={positive}
      warning={warning}
      negative={negative}
      onDismiss={() => removeMessage()}
    >
      <Message.Header>{message.header}</Message.Header>
      {body}
    </Message>
  );
};

const MessagesDisplayer = ({ location }) => {
  const queryResult = useQuery(GET_ALL_MESSAGES);

  if (!queryResult || !queryResult.data) return null; // Ajout d'un transition group ? le transition group devrait-il englober la query ?

  const { data } = queryResult;

  if (!data.messageSet) return null;

  const messageSet = data.messageSet.filter((m) => m.location === location);

  return (
    <Transition.Group
      as={List}
      duration={500}
      divided
      size="small"
      verticalAlign="middle"
    >
      {messageSet.map((message) => (
        <MessageDisplayer key={`message_${message.id}`} message={message} />
      ))}
    </Transition.Group>
  );
};

export default MessagesDisplayer;
