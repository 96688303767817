// React
import React from "react";

// Semantic ui
import { Container } from "semantic-ui-react";

// Components
import MessageDisplayer from "./MessageDisplayer";

// Constants
import { COMPONENTS } from "../../utils/Constants";

const MessageDisplayerToast = () => {
  return (
    <div className="toastmessages">
      <Container text>
        <MessageDisplayer location={COMPONENTS.COMMON.INDEX} />
      </Container>
    </div>
  );
};

export default MessageDisplayerToast;
